import { LockOpen, StoreOutlined } from "@material-ui/icons";

export let menuItems = [
  {
    type: "resource",
    name: "warehouses",
    icon: StoreOutlined,
    label: "Sucursales",
  },
];

export let menuItemsWithPassword = [
  ...menuItems,
  {
    name: "password-reset",
    icon: LockOpen,
    label: "Cambiar contraseña",
  },
];
