export let palette: any = {
  primary: {
    main: "#0D60EA",
    background: "#FAFBFF",
  },
  secondary: {
    light: "rgba(16, 77, 156, 0.1)",
    main: "#002A82",
  },
  success: {
    main: "#4caf50",
    contrastText: "#F8F8FA",
  },
};
