import React from "react";
import { Loading } from "react-admin";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { CssBaseline, Theme, ThemeProvider } from "@material-ui/core";
import { IntercomProvider } from "react-use-intercom";

import { MenuItemsProvider } from "../../../core/context/menu";
import { RbacProvider } from "@blackbox-vision/ra-rbac";

type Props = {
  items: any[];
  locale: any;
  theme: Theme;
  canActivate: any;
};

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || "";

// TODO: implement dark mode support
let AppProvider: React.FC<Props> = ({
  items,
  theme,
  locale,
  children,
  canActivate,
}) => {
  return (
    <RbacProvider canActivate={canActivate}>
      <IntercomProvider autoBoot={false} appId={INTERCOM_APP_ID}>
        <MenuItemsProvider items={items}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
              <CssBaseline />
              <React.Suspense fallback={<Loading />}>{children}</React.Suspense>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </MenuItemsProvider>
      </IntercomProvider>
    </RbacProvider>
  );
};

AppProvider.displayName = "AppProvider";

export default AppProvider;
