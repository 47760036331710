import React, { useState } from "react";
import {
  Typography,
  Grid,
  Container,
  DialogTitle,
  DialogContent,
  Button,
  InputAdornment,
  IconButton,
  Box,
  Card,
  CardContent,
} from "@material-ui/core";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import { ThemeProvider } from "@material-ui/styles";

import NewLogo from "../../assets/new-logo.png";
import { useStyles } from "./styles";
import { theme } from "../../core/theming";
import { required, TextInput } from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import { useAuth } from "./hooks";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import { Form } from "react-final-form";

const uiConfig: any = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/warehouses",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
};

const SignInScreen = () => (
  <StyledFirebaseAuth firebaseAuth={firebase.auth()} uiConfig={uiConfig} />
);

const Auth = () => {
  const classes = useStyles();

  return (
    <Container style={{ backgroundColor: "#FAFBFF" }} maxWidth={"lg"}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12} md={10}>
          <Card style={{ marginTop: "5%" }}>
            <CardContent>
              <img alt="logo" className={classes.image} src={NewLogo} />
              <Typography
                color={"primary"}
                variant="h5"
                style={{ margin: ".6em 0 1em 0" }}
                component="h1"
                align="center"
                paragraph
              >
                Consola de Proveedores
              </Typography>
              <SignInScreen />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export const ResetPassword = () => {
  let [showPassword, setShowPassword] = useState(false);
  const { loading, handleSubmit, redirect, success } = useAuth();

  return (
    <Dialog open={true}>
      <DialogTitle style={{ marginTop: "10px" }}>
        Cambiar contraseña
      </DialogTitle>
      <DialogContent style={{ overflowX: "hidden" }}>
        {success ? (
          <>
            <p>Contraseña actualizada correctamente</p>
            <Button
              variant="contained"
              color="primary"
              onClick={() => redirect("/")}
            >
              Listo
            </Button>
          </>
        ) : (
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit, hasValidationErrors }: any) => (
              <>
                <TextInput
                  source="oldPassword"
                  label={"Contraseña actual"}
                  disabled={loading}
                  validate={[required()]}
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOutlined />
                          ) : (
                            <VisibilityOffOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextInput
                  source="password"
                  label={"Nueva contraseña"}
                  disabled={loading}
                  validate={[required()]}
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOutlined />
                          ) : (
                            <VisibilityOffOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                >
                  <Button
                    variant={"contained"}
                    style={{ marginRight: "10px" }}
                    onClick={() => redirect("/")}
                  >
                    CANCELAR
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    style={{ marginLeft: "10px" }}
                    disabled={loading || hasValidationErrors}
                    type={"submit"}
                    variant={"contained"}
                    color={"primary"}
                  >
                    ACTUALIZAR
                  </Button>
                </Box>
              </>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export const LoginPage: React.FC<any> = (props) => (
  <ThemeProvider theme={theme}>
    <Auth {...props} />
  </ThemeProvider>
);

LoginPage.displayName = "LoginPage";
