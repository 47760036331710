import { makeStyles } from "@material-ui/core";

export let useStyles = makeStyles(({ palette }: any) => ({
  sidebarWhenClosed: {
    backgroundColor: palette.primary.background!,
    paddingLeft: 0,
  },
  sidebarWhenOpen: {
    backgroundColor: "#FFF",
    color: "#000",
    paddingLeft: 0,
  },
  appBar: {
    backgroundColor: "primary",
    boxShadow: "none",
  },
  listItem: {
    color: "#000",
  },
  menuLink: {
    color: "#000",
    width: 240,
  },
  logoutButton: {
    position: "absolute",
    bottom: 0,
  },
}));
