import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/auth";
import { Admin, RouteWithoutLayout } from "react-admin";
import esLocale from "date-fns/locale/es";
import { createBrowserHistory } from "history";
import { RbacResource as Resource } from "@blackbox-vision/ra-rbac";

import { theme } from "./core/theming";

import { menuItems, menuItemsWithPassword } from "./core/config/menu";

import { i18nProvider } from "./core/providers/i18n";
import { authProvider, getSignProvider } from "./core/providers/auth";
import { dataProvider } from "./core/providers/data";

import { canActivate } from "./core/helpers/canActivate";

import AppLayout from "./components/templates/app-layout";
import AppProvider from "./components/templates/app-provider";

import warehouseResourceProps from "./pages/warehouses";
import { LoginPage, ResetPassword } from "./pages/Auth";

if (window.location.hostname === "127.0.0.1") {
  const host = window.location.hostname;
  firebase.auth().useEmulator(`http://${host}:9099`);
  firebase.firestore().useEmulator(host, 8080);
  firebase.storage().useEmulator(host, 9199);
  firebase.functions().useEmulator(host, 5001);
}

let browserHistory = createBrowserHistory();

const routes = [
  <RouteWithoutLayout exact path="/password-reset" component={ResetPassword} />,
];

// TODO: remove this

const App = () => {
  const [isPasswordSignIn, setIsPasswordSignIn] = useState(true);

  useEffect(() => {
    let unsusbscribe = firebase.auth().onAuthStateChanged((user) => {
      if (!!user) {
        setIsPasswordSignIn(getSignProvider(user) === "password");
      }
    });
    return () => {
      unsusbscribe();
    };
  }, []);

  return (
    <AppProvider
      theme={theme}
      items={isPasswordSignIn ? menuItemsWithPassword : menuItems}
      locale={esLocale}
      canActivate={canActivate}
    >
      <Admin
        disableTelemetry
        title="Criptoladrillo"
        layout={AppLayout}
        loginPage={LoginPage}
        customRoutes={routes}
        history={browserHistory}
        i18nProvider={i18nProvider}
        dataProvider={dataProvider}
        authProvider={authProvider}
      >
        <Resource {...warehouseResourceProps} />
        <Resource name="operations" />
        <Resource name="operationsTemplates" />
        <Resource name="tokens" />
      </Admin>
    </AppProvider>
  );
};

export default App;
