import React from "react";
import { ResourceProps } from "react-admin";

let resourceProps: ResourceProps = {
  name: "warehouses",
  // We use React.lazy so we can lazy load modules
  list: React.lazy(
    () => import(/* webpackChunkName: "warehouse-list" */ "./list")
  ),
  show: React.lazy(
    () => import(/* webpackChunkName: "warehouse-show" */ "./show")
  ),
};

export default resourceProps;
