import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 0,
  },
  submitButton: {
    backgroundColor: "#075933",
    fontSize: 18,
    color: "#FFFFFF",
    marginTop: theme.spacing(),
    padding: "11px 0",
    "&:hover": {
      backgroundColor: "#075933",
    },
  },
  forgotButton: {
    color: "#075933",
    marginTop: 24,
    textTransform: "none",
    fontWeight: 500,
    marginLeft: "0px !important",
    padding: 0,
  },
  bodyContainer: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 8px",
  },
  image: {
    width: "100%",
    height: 75,
    objectFit: "cover",
  },
  welcomeTitle: {
    color: "#075933",
    marginTop: 16,
  },
}));
