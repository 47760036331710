import { FirebaseDataProvider as createDataProvider } from "react-admin-firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/database";
import "firebase/compat/functions";

import { firebaseAppCredentials } from "../config/firebase";

export let dataProvider = createDataProvider(firebaseAppCredentials, {
  logging: false,
  softDelete: false,
  persistence: "local",
  metaFieldCasing: "camel",
  associateUsersById: true,
  dontAddIdFieldToDoc: false,
  lazyLoading: {
    enabled: false,
  },
  firestoreCostsLogger: {
    enabled: true,
  },
});

export const db = firebase.firestore();
export const functions = firebase.functions();
