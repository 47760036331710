import { FirebaseAuthProvider as createAuthProvider } from "react-admin-firebase";

import { firebaseAppCredentials } from "../config/firebase";
import firebase from "firebase/compat/app";
import "firebase/auth";

let baseAuthProvider = createAuthProvider(firebaseAppCredentials, {
  logging: true,
});

export let authProvider = {
  ...baseAuthProvider,
  getIdentity: async () => {
    let user = await baseAuthProvider.getAuthUser();
    let claims = await baseAuthProvider.getJWTClaims();

    let { uid, email, photoURL, displayName } = user;

    return {
      id: uid,
      roles: claims?.roles,
      fullName: `${displayName ?? email ?? ""}`,
      avatar: `${photoURL ?? ""}`,
    };
  },
  checkAuth: async (params: any) => {
    let user: any = await baseAuthProvider.checkAuth(params);

    return !!user
      ? Promise.resolve()
      : Promise.reject({
          redirectTo: "/login",
        });
  },
  reauthenticate: async function (currentPassword: string) {
    const user = firebase.auth().currentUser;
    if (!!user?.email) {
      const cred = firebase.auth.EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      return await user.reauthenticateWithCredential(cred);
    }
  },
  changePassword: async function (oldPassword: string, password: string) {
    try {
      await this.reauthenticate(oldPassword);
      await firebase.auth().currentUser?.updatePassword(password);
    } catch (e: any) {
      console.warn(e.code);
      throw new Error(e);
    }
  },
};

export const getSignProvider = (user: firebase.User) => {
  return user?.providerData[0]?.providerId;
};
