import { useState } from "react";
import { useAuthProvider, useRedirect } from "react-admin";

export let useAuth = () => {
  let redirect = useRedirect();
  let authProvider = useAuthProvider();

  let [loading, setLoading] = useState(false);
  let [success, setSuccess] = useState(false);
  let [resetPassword, setResetPassword] = useState(false);

  let handlePasswordReset = async (values: any) => {
    try {
      await authProvider.changePassword(values.oldPassword, values.password);
      setResetPassword(false);
      setSuccess(true);
    } catch (err: any) {
      console.warn(err);
      alert(err);
    } finally {
      setLoading(false);
    }
  };

  let handleSubmit = async (values: any) => {
    setLoading(true);
    await handlePasswordReset(values);
  };

  return {
    loading,
    resetPassword,
    handleSubmit,
    success,
    redirect,
    setResetPassword,
  };
};
