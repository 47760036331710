export let typography: any = {
  h1: {
    fontWeight: "bold",
    fontSize: "2.5rem",
    lineHeight: 1.2,
    fontFamily: "'Poppins', sans-serif",
  },
  h2: {
    borderBottom: "3px solid",
    fontWeight: "bold",
    fontSize: "1.5rem",
    lineHeight: 2.2,
    color: "#383C41",
    textDecoration: "none",
    fontFamily: "'Poppins', sans-serif",
  },
  h3: {
    fontWeight: "bold",
    fontSize: "1.5rem",
    lineHeight: 1.3,
    fontFamily: "'Poppins', sans-serif",
  },
  h6: {
    fontSize: "1.2rem",
    fontWeight: 400,
    lineHeight: 1.1,
    fontFamily: "'Poppins', sans-serif",
  },
  body1: {
    fontSize: "0.875rem",
    fontWeight: 300,
    fontStyle: "normal",
    lineHeight: 1.2,
    fontFamily: "'Poppins', sans-serif",
  },
  caption: {
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "14px",
    fontFamily: "'Poppins', sans-serif",
  },
};
