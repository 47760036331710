import spanishMessages from "@blackbox-vision/ra-language-spanish";

export let esMessages = {
  ...spanishMessages,
  resources: {
    operations: {
      status: {
        signing: "Firmando",
        pending: "Pendiente",
        published: "Publicada",
        ok: "Confirmado",
        processing: "Procesando",
        fail: "Fallida",
        takeable: "Disponible",
        taken: "Tomado",
        cancelled: "Cancelado",
        confirmed: "Escaneado",
        deactivated: "Desactivado",
      },
    },
  },
};
